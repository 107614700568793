import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import ContactInfo from "../components/pages/ContactPage/partials/contactInfo/ContactInfo";
import {getRoutes} from '../components/shared/routes';
import AdditionalInformation from "../components/shared/additionalInformation/additionalInformation";
import PageTitle from "../components/shared/PageTitle/PageTitle";

const ContactPage = () => {
    const pageTitle = getRoutes().Contact.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <PageTitle>{pageTitle}</PageTitle>

            <ContactInfo/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default ContactPage;
