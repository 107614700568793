import React from "react";
import css from './contactInfo.module.scss';
import {useTranslation} from "react-i18next";
import MapDesktopImage from "../../../../../images/Contact/map_desktop.svg"
// import Map1MobileImage from "../../../../../images/Contact/map_1_mobile.svg"
import Map2MobileImage from "../../../../../images/Contact/map_2_mobile.svg"
import CompanyContactMail from "../../../../shared/CompanyContactMail/CompanyContactMail";
import CompanySalesMail from "../../../../shared/CompanySalesMail/CompanySalesMail";
import CompanySupportMail from "../../../../shared/CompanySupportMail/CompanySupportMail";
// import CanadaImg from "../../../../../images/Contact/contact_canada.svg";
import PolandImg from "../../../../../images/Contact/contact_poland.svg";

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    <div className={css.wrapper}>
      <div className={css.contentWrapper}>
        <img src={MapDesktopImage} alt={"deeplai contact map"} className={css.mapDesktopImage} />
        <img src={Map2MobileImage} alt={"deeplai contact map"} className={css.map2MobileImage} />
        {/*<img src={Map1MobileImage} alt={"deeplai contact map"} className={css.map1MobileImage} />*/}
        <div className={[css.textWrapper, css.textPolandWrapper].join(' ')}>
            <div className={css.text}>
              <div className={css.imageWrapper}>
                  <img src={PolandImg} alt={""} />
              </div>
                <div className={css.textHeader}>
                    <div className={css.textHeaderInner}>Poland</div>
                </div>
                <p>
               
                  <span className={css.text2}> Main Office</span><br/>
                  <span>ul. Zemborzycka 72</span><br/>
                  <span>20-445 Lublin</span><br/>
                  <span>Polska</span>
                </p>
                <p>
                  <span>
                    Phone: +48 81 743 51 09
                  </span>
                </p>
            </div>
        </div>
        {/*<div className={[css.textWrapper, css.textCanadaWrapper].join(' ')}>
            <div className={css.text}>
              <div className={css.imageWrapper}>
                  <img src={CanadaImg} alt={""} />
              </div>
                <div className={css.textHeader}>
                    <div className={css.textHeaderInner}>Canada</div>
                </div>
                <p>
                  <span>702-1641 Lonsdale Ave</span><br/>
                  <span>North Vancouver, BC</span><br/>
                  <span>V7M 2J5</span><br/>
                  <span>Canada</span>
                </p>
                <p>
                  <span>
                    Phone: +1 778 819 3279
                  </span>
                </p>
            </div>
        </div>*/}
      </div>
      <div className={css.mailsWrapper}>
        <div className={css.mailWrapper}>Email: <CompanyContactMail /></div>
        <div className={css.mailWrapper}>Sales: <CompanySalesMail /></div>
        <div className={css.mailWrapper}>Tech-support: <CompanySupportMail /></div>
      </div>
      <div className={css.footerWrapper}>
          District Court for Lublin-Wschód in Lublin with office in Świdnik (VI Commercial Department of the Court Register) | KRS: 0000803809 | NIP / VAT ID: 9462691664 | The share capital of the Company (fully paid): 5000 PLN
      </div>
    </div>
  )
};

export default ContactInfo;
